import './index.scss'
import close from '../menu/img/close.svg'

const Modal = ({ open, onClose, children }) => {
  return (
    <div className={`modal ${open ? 'opened' : ''}`}>
      {open && (
        <>
          <div className="modal__content">
            <img src={close} alt="Close" className="modal__close" onClick={onClose} />
            {children}
          </div>
          <div className="modal__overlay" onClick={onClose} />
        </>
      )}
    </div>
  )
}

export default Modal
