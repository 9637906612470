import './index.scss'
import productsItems from '../products/productsItems'
import Slider from 'react-slick'
import {
  Link
} from 'react-router-dom'

const ProductMore = ({ productId }) => {
  const items = productsItems.filter(i => i?.id !== productId)
  const settings = {
    className: 'slider',
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  }
  return (
    <section className="product-more">
      <h3 className="title">Подивіться ще</h3>
      <Slider
        {...settings}
      >
        {items.map((item, index) => {
          return (
            <Link to={item.link} className="product-more__item" key={index}>
              <img
                className="product-more__img"
                src={require(`../../img/products/product-${item.id}/img-1.jpeg`)}
                srcSet={`${require(`../../img/products/product-${item.id}/img-1@2x.jpeg`)} 2x`}
              />
              <h3 className="product-more__title">
                {item.title}
              </h3>
            </Link>
          )
        })}
      </Slider>
    </section>
  )
}

export default ProductMore