import './index.scss'

const Textarea = ({
  placeholder,
  onChange,
  error,
}) => {
  return (
    <div className={`input ${error ? 'error' : ''}`}>
      <textarea
        className="input__field"
        placeholder={placeholder}
        rows={4}
        onChange={onChange}
      />
      <div className="input__error">
        {error}
      </div>
    </div>
  )
}

export default Textarea