import './index.scss'
import Modal from '../modal'

const InfoModal = ({ open, onClose, text, error }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <p className={`modal__text ${error ? 'modal__text--error' : ''}`}>
        {text}
      </p>
    </Modal>
  )
}

export default InfoModal