import Form from '../form'
import Modal from '../modal'

const OrderModal = ({ open, onClose, order, onSuccess, onError }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <h3 className="title">Оформити замовлення</h3>
      <Form
        type="order"
        order={order}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Modal>
  )
}

export default OrderModal