import './index.scss'

const ProductCount = ({ price, count, setCount }) => {
  return (
    <div className="product-count">
      <p className="product-count__price">
        {Math.round((price || 0) * count)} грн
      </p>
      <div className="product-count__counter">
        <button
          onClick={() => setCount(count - 1)}
          disabled={count === 1}
        >
          -
        </button>
        <div>
          {count}
        </div>
        <button
          onClick={() => setCount(count + 1)}
        >
          +
        </button>
      </div>
    </div>
  )
}

export default ProductCount