import { useState } from 'react'
import './index.scss'

const Input = ({
  type = 'text',
  placeholder,
  onChange,
  error,
}) => {
  const [value, setValue] = useState('')

  const handleOnInput = (e) => {
    if (type === 'tel') {
      let nextVal = e.target.value
      if (!value) {
        if (nextVal.startsWith('0')) {
          nextVal = '+38' + nextVal
        } else if (nextVal.startsWith('380')) {
          nextVal = '+' + nextVal
        } else if (!nextVal.startsWith('+380')) {
          nextVal = '+380' + nextVal
        }
      }
      setValue(nextVal)
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <div className={`input ${error ? 'error' : ''}`}>
      <input
        className="input__field"
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onInput={handleOnInput}
        name={placeholder}
      />
      <div className="input__error">
        {error}
      </div>
    </div>
  )
}

export default Input
